@import '../../variables';


.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.50);
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 0;
  @include transition;

  &.visible {
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    z-index: 12;
  }

  .modal-body {
    background-color: $color-white;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25);
    color: $color-primary;
    font-family: $font-sans-serif;
    height: auto;
    left: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5rem 3rem 3rem;
    position: relative;
    top: 50%;
    width: 60%;
    @include transition;
    @include transform(translate(-50%, -50%));
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction(row);
    @include align-items(flex-start);
    @include justify-content(center);

    &::before {
      content: '';
      position: absolute;
      top: 1rem;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      border: 2px solid $color-primary;
      z-index: -1;
    }

    strong {
      display: block;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: underline;
    }

    .modal-close {
      background-color: transparent;
      background-image: url("../../assets/img/icon-cross-blue.png");
      background-repeat: no-repeat;
      background-size: cover;
      border: 0;
      height: 3rem;
      padding: 0;
      position: absolute;
      right: 2rem;
      top: 2rem;
      width: 3rem;
      z-index: 10;

      &:hover, &:focus {
        opacity: 0.5;
      }
    }

    .modal-content {
      width: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);

      .subtitle {
        font-family: $font-sans-serif-bold;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }

      h2,
      h3 {
        text-align: center;
        width: 100%;
      }

      .button-group {
        margin-top: 1rem;
        width: 100%;
      }

      img.small-img {
        display: block;
        margin: 0 auto;
        max-width: 50rem;
      }

      form {
        img {
          max-width: 20rem;
        }

        .form-group.one-row {
          margin-top: 3rem;
          text-align: center;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          input[type='email'] {
            max-width: 30rem;
          }

          .submit {
            margin: 0;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .modal-wrapper {
    .modal-body {
      width: 90%;

      .modal-close {
        right: 1.5rem;
        top: 1.5rem;

      }

      .modal-content {
        .button-group {
          margin-top: 0;

          button,
          .button {
            margin: 0.5rem auto;
          }
        }

        form .form-group.one-row {
          display: block;

          .submit {
            margin-top: 1rem;
            margin-left: 0;
          }
        }
      }
    }
  }
}
