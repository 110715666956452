@import '../../variables';

.hero-wrapper.faq {
  background-image: url('../../assets/img/faq-hero.jpg');
  background-position: right center;
  background-size: contain;
  margin: 0 4%;
  padding: 0;
  padding-top: 2%;
  text-align: left;
  width: 92%;
  @include align-items(flex-start);
  @include justify-content(center);

  h1,
  .descr {
    color: $color-primary;
    text-shadow: none;
    width: 40vw;
  }

  .descr {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 4rem;
    text-align: left;
  }
}

.tooltip {
  background-color: $color-primary;
  border-radius: .5rem;
  color: $color-white;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  padding: 2rem;
  position: absolute;
  left: 107%;
  top: 50%;
  text-align: left;
  visibility: hidden;
  width: 30rem;
  z-index: 1;
  @include transform(translateY(-50%));
  @include transition;

  &::after {
    border: 1.5rem solid transparent;
    border-right-color: $color-primary;
    content: '';
    margin-top: -1.5rem;
    position: absolute;
    top: 50%;
    right: 100%;
  }
}

.body-wrapper.faq {
  text-align: left;
  padding-top: 6%;
  @include align-items(flex-start);
  @include justify-content(flex-start);
  @include flex-wrap(nowrap);
  
  .faq-nav {
    margin-right: 10rem;

    li {
      a {
        border: 2px solid $color-pampas;
        border-radius: 4rem;
        font-size: 1.8rem;
        font-weight: 700;
        height: 6rem;
        line-height: 1.8rem;
        margin-bottom: 1rem;
        padding: 1rem;
        position: relative;
        text-align: center;
        width: 25rem;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        &:hover {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-white;

            .tooltip {
              opacity: 1;
              visibility: visible;
            }
        }
      }

      &.active a {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
      }
    }
  }

  .service-component {
    margin-bottom: 4rem;

    h2,
    .descr {
      margin-top: 0;
      margin-bottom: 3rem;
      text-align: left;
    }

    .descr {
      font-size: 2rem;
    }

    h3 {
      font-size: 3.6rem;

      img {
        width: 2.5rem;
      }
    }

    a {
      color: $color-link;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    input,
    textarea,
    .custom-select,
    .error {
      max-width: 35rem;
    }

    input,
    textarea {
      border: 2px solid $color-pampas;
      border-radius: .5rem;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: normal;
      height: 5rem;
      padding: .2rem 1rem;
      

      &::placeholder {
        color: $color-placeholder;
        font-weight: 400;
        text-transform: none;
      }
    }

    textarea {
      min-height: 10rem;
      padding: 1rem;
    }

    .custom-select {
      .form__control {
        border: 2px solid $color-pampas;
        border-radius: .5rem;
        cursor: pointer;

        .form__value-container {
          height: 5rem;
          padding: .2rem 1rem;
          width: 100%;
          @include flex-wrap(nowrap);

          .form__placeholder {
            color: $color-placeholder;
            font-weight: 400;
            margin: 0;
            position: relative;
            top: 0;
            @include transform(none);
          }

          .form__single-value {
            color: $color-primary;
            font-weight: 700;
            margin: 0;
            overflow: visible;
            position: relative;
            top: auto;
            @include transform(none);
          }
        }

        .form__indicators {
          .form__indicator-separator {
            display: none;
          }

          .form__indicator {
            background: url('../../assets/img/icon-arrow-grey.svg') no-repeat center;
            color: $color-secondary;
            padding: 1rem;
            width: 4rem;

            svg {
              display: none;
            }

            &.form__clear-indicator {
              background-image: url('../../assets/img/icon-cross-grey.svg');
            }
          }
        }
      }

      .form__menu {
        border: none;
        border-radius: .5rem;
        box-shadow: 0 .5rem 2rem rgba(0, 0, 0, .15);
        margin: 0;
      }
    }

    .is-valid {
      .field-wrapper {
        input,
        textarea,
        .form__control {
          border-color: $color-green;
        }
      }
    }

    .is-invalid {
      .field-wrapper {
        input,
        textarea,
        .form__control {
          border-color: $color-error;
        }
      }
    }

    table {
      margin-bottom: 4rem;

      th {
        font-size: 1.2rem;
        letter-spacing: -.01rem;
      }

      td {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.8rem;
        vertical-align: middle;

        &:last-child {
          font-weight: 300;
          text-align: left;
        }
      }
    }

    button.big-btn {
      border: 2px solid $color-primary;
      border-radius: .5rem;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: 700;
      height: 5rem;
      letter-spacing: 0;
      padding: .4rem .5rem;
      text-transform: capitalize;

      &:hover {
        color: $color-white;
      }
    }

    .form-container {
      max-width: 40rem;

      .field-wrapper {
        margin-bottom: 2rem;
        min-width: 35rem;

        .error {
          color: $color-error;
          font-size: 1.4rem;
          margin-top: .5rem;
        }
      }

      .field-info {
        margin-bottom: 2rem;
        @include flexbox;
        @include flex-wrap(wrap);

        .field-wrapper {
          margin-bottom: 0;
        }
      }

      .modal-link {
        cursor: pointer;
        font-size: 1.4rem;
        margin-top: .5rem;
      }

      .circle {
        background-color: $color-primary;
        border-radius: 50%;
        color: $color-white;
        cursor: pointer;
        font-weight: 700;
        height: 2.5rem;
        line-height: 2.5rem;
        margin-left: 1rem;
        margin-top: 1.5rem;
        position: relative;
        text-align: center;
        width: 2.5rem;

        .tooltip {
          left: 155%;
        }

        &:hover {
          .tooltip {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .form-actions {
      margin: 2rem 0;
      max-width: 35rem;
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      
      button.big-btn {
        width: 10rem;

        &[type=submit] {
          background-color: $color-primary;
          color: $color-white;
          margin-left: 2rem;
          width: auto;
          @include flex-grow(1);

          &:hover {
            background-color: $color-white;
            color: $color-primary;
          }
        }
      }
    }

    .service-result {
      margin-top: -18rem;
      @include flexbox;
      @include align-items(flex-end);

      .result,
      & div:first-child {
        margin-right: 4rem;
        max-width: 35rem;
        width: 100%;
      }

      .result {
        background-color: $color-pampas;
        border-radius: 1rem;
        font-size: 2rem;
        line-height: normal;
        min-height: 16rem;
        padding: 2rem;
        text-align: center;
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
      }

      .product-image {
        height: 40rem;
        margin-bottom: -2rem;

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }

        &.unavailable {
          opacity: .5;
        }
      }
    }

    .product-info {
      line-height: normal;
      margin: 4rem 0;
    }

    .instructions {
      margin-top: 4rem;

      .example-wrapper {
        @include flexbox;
        @include justify-content(space-between);;
        
        .example {
          width: 45%;

          img {
            max-width: 30rem;
          }
        }
      }
    }
  }
}

.float-info {
  background-color: $color-primary;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, .25);
  color: $color-white;
  cursor: pointer;
  font-size: 3.6rem;
  height: 7rem;
  position: fixed;
  right: 2%;
  bottom: 5%;
  width: 7rem;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  &::before {
    content: '?';
  }

  .popup {
    border-radius: 1rem;
    box-shadow: 0 .5rem 2rem rgba(0, 0, 0, .25);
    line-height: normal;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 10rem;
    text-align: center;
    visibility: hidden;
    width: 27rem;
    z-index: 1;
    @include transition;

    .popup-header {
      background-color: $color-primary;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      color: $color-white;
      font-size: 2rem;
      font-weight: 700;
      padding: 1rem;
    }

    .popup-body {
      background-color: $color-pampas;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: 400;
      padding: 2rem;

      strong {
        display: block;
        font-size: 2rem;
        text-wrap: nowrap;
      }
    }
  }

  &:hover {
    &::before{
      background-image: url("../../assets/img/icon-cross.svg");
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 2.6rem;
      width: 2.6rem;
    }

    .popup {
      opacity: 1;
      visibility: visible;
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.faq {
    background-size: 250%;
    background-position: center;
    height: 40rem;
    margin: 0;
    padding: 4%;
    position: relative;
    width: 100%;

    &::after {
      background-color: rgba(0, 0, 0, .5);
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    h1,
    .descr {
      color: $color-white;
      text-align: center;
      text-shadow: 0 .5rem 1rem rgba(0, 0, 0, .75);
      width: 100%;
      z-index: 1;
    }

    .descr {
      font-weight: 700;
      margin-top: 0;
    }
  }

  .tooltip {
    left: 112%;
  }

  .body-wrapper.faq {
    padding-top: 4%;

    .faq-nav {
      margin-right: 2rem;

      li a {
        font-size: 1.4rem;
        line-height: 1.4rem;
        width: 15rem;
      }
    }

    .service-component {
      h2,
      .descr {
        margin-bottom: 2rem;
      }

      h2 {
        font-size: 4rem;
      }

      .descr {
        font-size: 1.8rem;
      }

      input,
      textarea,
      .custom-select,
      .error {
        max-width: none;
      }

      .form-container {
        max-width: none;

        .field-wrapper {
          width: 90%;
        }

        .circle .tooltip {
          left: auto;
          right: 155%;

          &::after {
            border-right-color: transparent;
            border-left-color: $color-primary;
            right: auto;
            left: 100%;
          }
        }
      }

      .form-actions {
        max-width: none;
        width: 90%;
      }

      .service-result {
        margin-top: 2rem;
        @include align-items(center);

        .result,
        & div:first-child {
          max-width: none;
          min-height: auto;
        }

        .result {
          max-width: none;
          min-height: auto;
          width: 60%;
        }

        .product-image {
          height: 25rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .hero-wrapper.faq {
    height: 30rem;
  }

  .body-wrapper.faq {
    @include flex-wrap(wrap);

    .faq-nav {
      border-bottom: 1px solid $color-primary;
      margin-right: 0;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      width: 100%;
      @include flexbox;
      @include justify-content(center);
      @include flex-wrap(wrap);

      li {
        width: 50%;

        a {
          margin-right: 1rem;
          width: auto;

          .tooltip {
            height: 11rem;
            left: auto;
            text-align: center;
            top: -128%;
            width: 20rem;
            @include flexbox;
            @include align-items(center);

            &::after {
              border-right-color: transparent;
              border-top-color: $color-primary;
              margin-top: auto;
              margin-right: 45%;
              top: 100%;
              right: 0%;
            }
          }
        }
      }
    }

    .service-component {
      h2,
      h3,
      .descr,
      p,
      .product-info {
        text-align: center;
      }

      button.big-btn {
        margin: 0 auto;
      }

      .form-container {
        .field-wrapper,

        .field-wrapper {
          min-width: auto;
        }
      }

      .form-actions {
        width: 82vw;
      }

      .product-info {
        margin: 0 0 2rem 0;
      }

      .instructions {
        .example-wrapper {
          @include flex-wrap(wrap);
          
          .example {
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .float-info {
    display: none;
  }
}